export const misunderstood = [
  [
    "ANGRY",
    "ANNOYED",
    "ANXIOUS",
    "ARROGANT",
    "ASHAMED",
    "AWKWARD",
    "BORED",
    "CONFUSED",
    "COWARDLY",
    "CRUSHED",
  ],
  [
    "DEFENSIVE",
    "DEJECTED",
    "DEPRESSED",
    "DEVASTATED",
    "DISAPPOINTED",
    "DISGUSTED",
    "DISHEARTENED",
    "DISTRAUGHT",
    "EMBARRASSED",
    "EMPTY",
  ],
  [
    "ENRAGED",
    "ENVIOUS",
    "EXHAUSTED",
    "FEARFUL",
    "FRUSTRATED",
    "FURIOUS",
    "GRIEVED",
    "GUILTY",
    "HEARTBROKEN",
    "HELPLESS",
  ],
  [
    "HOPELESS",
    "HURT",
    "HUMILIATED",
    "HYSTERICAL",
    "IMPATIENT",
    "INDECISIVE",
    "INSECURE",
    "INTIMIDATED",
    "INTOLERANT",
    "IRRITATED",
  ],
  [
    "JEALOUS",
    "JUDGMENTAL",
    "LONELY",
    "LOST",
    "MAD",
    "MISERABLE",
    "NUMB",
    "OVERWHELMED",
    "PARANOID",
    "PETRIFIED",
  ],
  [
    "REJECTED",
    "RESENTFUL",
    "SAD",
    "SELF-CONSCIOUS",
    "SHY",
    "STRESSED",
    "STUCK",
    "SUICIDAL",
    "SUPERIOR",
    "SUSPICIOUS",
  ],
  [
    "TENSE",
    "TIMID",
    "TIRED",
    "TORTURED",
    "TRAPPED",
    "UNCERTAIN",
    "UNCOMFORTABLE",
    "VULNERABLE",
    "WEAK",
    "WORRIED",
  ],
];

export const understood = [
  [
    "ABSORBED",
    "ACCEPTING",
    "AFFECTIONATE",
    "ALLOWING",
    "AMAZED",
    "AMUSED",
    "APPROACHABLE",
    "BLISSFUL",
    "CALM",
    "CAREFREE",
  ],
  [
    "CARING",
    "CHEERFUL",
    "COMFORTABLE",
    "COMPASSIONATE",
    "CONSIDERATE",
    "CONTENT",
    "COURAGEOUS",
    "CURIOUS",
    "DELIGHTED",
    "DEVOTED",
  ],
  [
    "ECSTATIC",
    "ELATED",
    "EMPATHETIC",
    "ENCHANTED",
    "ENGAGED",
    "ENGROSSED",
    "ENTHUSIASTIC",
    "EUPHORIC",
    "EXCITED",
    "FASCINATED",
  ],
  [
    "FLEXIBLE",
    "FOCUSED",
    "FORGIVING",
    "FULFILLED",
    "GENTLE",
    "GRACIOUS",
    "GRATEFUL",
    "HAPPY",
    "HUMBLE",
    "INSPIRED",
  ],
  [
    "INTERESTED",
    "INTRIGUED",
    "INQUISITIVE",
    "JOVIAL",
    "JOYFUL",
    "LOVING",
    "MELLOW",
    "NON-JUDGMENTAL",
    "OPTIMISTIC",
    "PATIENT",
  ],
  [
    "PEACEFUL",
    "PLAYFUL",
    "RECEPTIVE",
    "RELAXED",
    "RELIEVED",
    "RESPECTFUL",
    "SATISFIED",
    "SECURE",
    "SENSITIVE",
    "SENTIMENTAL",
  ],
  [
    "SERENE",
    "STABLE",
    "SURPRISED",
    "TENDER",
    "THANKFUL",
    "THOUGHTFUL",
    "TOLERANT",
    "TOUCHED",
    "WARM",
    "WELCOMING",
  ],
];
