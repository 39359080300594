import { useState } from "react";
import { misunderstood, understood } from "./arrs";
import "./App.scss";

function Button({ text, addEntry, removeEntry }) {
    const [active, setActive] = useState(false);

    function toggleActive() {
        setActive(!active);

        if (active) {
            removeEntry(text);
        } else {
            addEntry(text);
        }
    }

    return (
        <button type="button" className={active ? "active" : ""} onClick={toggleActive}>
            {text}
        </button>
    );
}

function Column({ column, addEntry, removeEntry }) {
    return (
        <>
            {column.map((text, index) => (
                <Button
                    key={`button-${index}`}
                    text={text}
                    addEntry={addEntry}
                    removeEntry={removeEntry}
                />
            ))}
        </>
    );
}

function Buttons({ list, addEntry, removeEntry }) {
    return (
        <>
            {list.map((column, index) => (
                <div key={`column-${index}`} className={`column column${index + 1}`}>
                    <Column column={column} addEntry={addEntry} removeEntry={removeEntry} />
                </div>
            ))}
        </>
    );
}

function App() {
    const [results, setResults] = useState([]);

    function addEntry(text) {
        console.log("add");
        const newResults = [...results];
        newResults.push(text);
        setResults(newResults);
    }

    function removeEntry(text) {
        console.log("remove");
        const newResults = [...results];
        const itemIndex = newResults.indexOf(text);

        newResults.splice(itemIndex, 1);
        setResults(newResults);
    }
    console.log("results: ", results);

    return (
        <div className="App">
            <div className="boards">
                <div className="board understood">
                    <Buttons list={understood} addEntry={addEntry} removeEntry={removeEntry} />
                </div>
                <div className="board misunderstood">
                    <Buttons list={misunderstood} addEntry={addEntry} removeEntry={removeEntry} />
                </div>
            </div>

            <div className="results">{results.sort().join(", ").toLowerCase()}</div>
        </div>
    );
}

export default App;
